export const navItems = [
    {
        id: 1,
        title: "About Me",
        path: '/about',
        className: 'nav-item'
    },   
    {
        id: 2,
        title: "Projects",
        path: '/projects',
        className: 'nav-item'
    },   
]